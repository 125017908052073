import * as _ from "lodash";
import { IForm } from "../../interface/IForm";
import { IFormElement } from "../../interface/IFormElement";
import { UtilHelper } from "./index";
import {
    Axios
} from "../../../../../api/service/Axios";
import { IElementUpdate } from "../../interface/IElementUpdate";
import { IFormGroup } from "../../interface/IFormGroup";
import { IOption } from "../../interface/IOption";
import { UI_AUTH_HEADER_NAMES, UI_ELEMENT_AUTH_TYPES, UI_ELEMENT_API_METHODS, UI_ELEMENT_TYPES, EXCLUDE_ELEMENT } from "../../../config";

// Form element helper class
export class FormElementHelper{
    private utilHelper: UtilHelper;
    constructor(){
        this.utilHelper = new UtilHelper();
    }

    /**
     * Find if any of the element parent value is empty
     *
     * @param {IForm[]} forms.
     * @param {id} element.
     * @return IFormElement | null.
     */
     isAnyParentEmpty = (forms: IForm[], parentElements: number[]): boolean => {
         let isEmpty = false;
         for(const form of forms){
             for(const formGroup of form.groups){
                 for(const formElement of formGroup.elements){
                     if(!_.isEqual(parentElements.indexOf(formElement.id), -1) && formElement.is_required){
                         if(this.utilHelper.isEmptyElementValue(formElement, formElement.value)){
                             isEmpty = true;
                         }
                     }
                 }
             }
         }
         return isEmpty;
     }
     /**
     * Find if any of the element parent value is empty
     *
     * @param {IForm} forms.
     * @param {id} element.
     * @return boolean.
     */
    isAnyParentEmptyForDataSet = (form: IForm, parentElements: number[]): boolean => {
        let isEmpty = false;
        for(const formGroup of form.groups){
            for(const formElement of formGroup.elements){
                if(!_.isEqual(parentElements.indexOf(formElement.id), -1)){
                    if(this.utilHelper.isEmptyElementValue(formElement, formElement.value)){
                        isEmpty = true;
                    }
                }
            }
        }
        return isEmpty;
    }

    /**
     * Find a form element
     *
     * @param {IForm[]} forms.
     * @param {id} element.
     * @return IFormElement | null.
     */
    findFormElement = (forms: IForm[], id: number): IFormElement | null => {
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.id, id)){
                        return formElement;
                    }
                }
            }
        }
        return null;
    }

    /**
     * Find child element for a date form element
     *
     * @param {IForm[]} forms.
     * @param {id} element.
     * @return IFormElement | null.
     */
    findDateChildFormElement = (forms: IForm[], id: number): IFormElement | null => {
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.parent_compare_element_id, id)){
                        return formElement;
                    }
                }
            }
        }
        return null;
    }


    /**
     * Find all child elements
     *
     * @param {IForm[]} forms.
     * @param {id} element id.
     * @return {FormElements} IFormElement[].
     */
    findChildElements = (forms: IForm[], id: number): IFormElement[] => {

        const FormElements:IFormElement[] = [];
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(!_.isEqual(formElement.parent_elements.indexOf(id), -1)){
                        FormElements.push(formElement);
                    }
                }
            }
        }
        return FormElements;
    }

    /**
     * Check if element has child elements
     *
     * @param {IForm[]} forms.
     * @param {id} element id.
     * @return {FormElements} IFormElement[].
     */
    hasChildElements = (forms: IForm[], id: number): boolean =>{
        return !_.isEmpty(this.findChildElements(forms, id));
    }
    /**
     * Create parents value object for Post request
     *
     * @param {IForm[]} forms.
     * @param {number[]} parentElements.
     * @return object.
     */
    getParentValuesForPOSTRequest = (forms: IForm[], parentElements: number[]): any => {
        const ParentValues:any = {};
        forms.forEach((form: IForm)=>{
            form.groups.forEach((formGroup: IFormGroup)=>{
                formGroup.elements.forEach((formElement: IFormElement)=>{
                    if(!_.isEqual(parentElements.indexOf(formElement.id), -1)){
                        if(!_.isNull(formElement.form_element_auth) && 
                        formElement.form_element_auth.in_header){
                            return;
                        }
                        const Value = (formElement.value || "").toString().trim();

                        if(!formElement.is_required && _.isEmpty(Value)){
                            return;
                        }
                        const Key = _.toLower(formElement.labelId);
                        ParentValues[Key] = formElement.value;
                    }
                });
            });
        });
        return ParentValues;
    }
    /**
     * Create parents value string for Get request
     *
     * @param {IForm[]} forms.
     * @param {number[]} parentElements.
     * @return string.
     */
    getParentValuesForGETRequest = (forms: IForm[], parentElements: number[]): string => {
        const ParentValues: any = [];

        forms.forEach((form: IForm)=>{
            form.groups.forEach((formGroup: IFormGroup)=>{
                formGroup.elements.forEach((formElement: IFormElement)=>{
                    if(!_.isEqual(parentElements.indexOf(formElement.id), -1)){

                        if(!_.isNull(formElement.form_element_auth) && 
                        formElement.form_element_auth.in_header){
                            return;
                        }

                        const Value = (formElement.value || "").toString().trim();

                        if(!formElement.is_required && _.isEmpty(Value)){
                            return;
                        }
                        const Key = _.toLower(formElement.labelId);
                        
                        if(formElement.is_multi && _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)){
                            const Values = this.utilHelper.getElementValue(formElement);

                            for(const value of Values){
                                ParentValues.push(`${Key}[]=${encodeURIComponent(value)}`);
                            }
                        }
                        else{
                            ParentValues.push(`${Key}=${encodeURIComponent(this.utilHelper.getElementValue(formElement))}`);
                        }
                    }
                });
            });
        });

        return ParentValues.join("&");
    }

    /**
     * Generate API data based on request methods
     *
     * @param {IFormElement} formElement.
     * @return any.
     */
    getConfigurationData = async (forms: IForm[],formElement: IFormElement): Promise<any> =>{
        let Data:any;
        const ParentElements = formElement.parent_elements;
        const Headers = this.getHeadersValueFromAuthElement(forms, ParentElements);
           
        if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
            const GETParams = this.getParentValuesForGETRequest(forms, ParentElements);
            const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
            Data = await Axios.get(`${formElement.configuration_api_url}${QueryParams}`, {headers:{...Headers}});
        }
        else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
            const POSTData = this.getParentValuesForPOSTRequest(forms, ParentElements);
            Data = await Axios.post(formElement.configuration_api_url, POSTData, {headers:{...Headers}});
        }
        return Data;   
    }

    


    /**
     * Generate API data based on request methods
     *
     * @param {IForm[]} forms.
     * @param {number[]} parentElements.
     * @return any.
     */
    getAPIData = async (forms: IForm[], formElement: IFormElement): Promise<any> =>{

        let Data:any;
        const ParentElements = formElement.parent_elements;
        const Headers = this.getHeadersValueFromAuthElement(forms, ParentElements);
        if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
            const GETParams = this.getParentValuesForGETRequest(forms, ParentElements);
            const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
            Data = await Axios.get(`${formElement.api_url}${QueryParams}`, {headers:{...Headers}});
        }
        else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
            const POSTData = this.getParentValuesForPOSTRequest(forms, ParentElements);
            Data = await Axios.post(formElement.api_url, POSTData, {headers:{...Headers}});
        }
        return Data.data;                 
    }

    /**
     * Generate API Value data based on request methods
     *
     * @param {IForm[]} forms.
     * @param {number[]} parentElements.
     * @return any.
     */
    getAPIValueData = async (forms: IForm[], formElement: IFormElement): Promise<any> =>{

        let Data:any;
        const ParentElements = formElement.parent_elements;
        const Headers = this.getHeadersValueFromAuthElement(forms, ParentElements);
        
        const APIValueURL = (formElement.api_value_url || "").trim().toLowerCase().trim();
        const APIURL = (formElement.api_url || "").trim().toLowerCase().trim();

        if(_.isEqual(APIValueURL, APIURL) && formElement.is_multi){
            return formElement.dataSource;
        }

        if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
            const GETParams = this.getParentValuesForGETRequest(forms, ParentElements);
            const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
            Data = await Axios.get(`${formElement.api_value_url}${QueryParams}`, {headers:{...Headers}});
        }
        else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
            const POSTData = this.getParentValuesForPOSTRequest(forms, ParentElements);
            Data = await Axios.post(formElement.api_value_url, POSTData, {headers:{...Headers}});
        }
        return Data.data;                 
    }

    /**
     * Get headers value from parent auth (google or facebook) element
     *
     * @param {IForm[]} forms.
     * @param {number[]} parentElements.
     * @return object.
     */
     getHeadersValueFromAuthElement = (forms: IForm[], parentElements: number[]): any => {
         const ParentValues:any = {};

         forms.forEach((form: IForm)=>{
             form.groups.forEach((formGroup: IFormGroup)=>{
                 formGroup.elements.forEach((formElement: IFormElement)=>{
                    if(!_.isEqual(parentElements.indexOf(formElement.id), -1)){
                        if(!_.isNull(formElement.form_element_auth) && 
                        formElement.form_element_auth.in_header){
                   
                            if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.GOOGLE)){
                                ParentValues[UI_AUTH_HEADER_NAMES.GOOGLE] = formElement.value;
                            }
                            else if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.FACEBOOK)){
                                ParentValues[UI_AUTH_HEADER_NAMES.FACEBOOK] = formElement.value;
                            }
                            else if(_.isEqual(formElement.form_element_auth.type, UI_ELEMENT_AUTH_TYPES.TIKTOK)){
                                ParentValues[UI_AUTH_HEADER_NAMES.TIKTOK] = formElement.value;
                            }
                        }
                    }
                 });
             });
         });
         return ParentValues;
     }

    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentElements = (forms: IForm[], element: IElementUpdate, dataSet?: number): any =>{

        let updatedForms: any = forms.map((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{
                    let isVisible = formElement.is_visible;

                    if(_.isEqual(formElement.depends_on, element.formElement.id)){
                        let elementValue = this.utilHelper.getElementValue({
                            ...element.formElement,
                            value: element.value,
                            dataSet
                        });
                        elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                        const Dependentvalues = _.toLower(formElement.dependent_values || "").toString().trim();
                        if(_.isEqual(elementValue.length, 0)){
                            isVisible = false;
                        }else{
                            isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);
                            // code for assiging default values 
                            if(_.isNull(formElement.value) && _.isEqual(formElement.is_default_value,true) && !_.isNull(formElement.default_value)){
                                if(!_.isNull(formElement.list_names) && _.isEqual(formElement.type_id,UI_ELEMENT_TYPES.SELECT_INPUT)){
                                    let default_value_obj:any=null;
                                    if(!_.isEqual(formElement.list_names,formElement.list_values)){
                                         default_value_obj = _.find(formElement.dataSource,{label:formElement.default_value});
                                    }else{
                                        default_value_obj = {label:formElement.default_value,value:formElement.default_value};
                                    }
                                    if (_.isEqual(formElement.is_multi, 1)) {
                                        formElement.value = [default_value_obj];
                                    }else{
                                        formElement.value = default_value_obj;
                                    }

                                }else{
                                    formElement.value = formElement.default_value;
                                }
                                formElement.touched = true;
                                formElement.error = "";
                                formElement.isValid = true;
                            }
                        }
                        if(!isVisible){
                            formElement.value = null;
                        }
                      
                    }

                    return {
                        ...formElement,
                        is_visible: isVisible
                    };
                })
            }))
        }));

        updatedForms = this.findDependentParentElements(updatedForms);

        return updatedForms;
    }

    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
     findDependentParentElements = (forms: IForm[]): any =>{

        const Forms = forms;

        for(let iForm=0;iForm< Forms.length;iForm++){
            const Groups = Forms[iForm].groups; 
            for(let iFormGroup=0;iFormGroup< Groups.length;iFormGroup++){
                const Elements = Groups[iFormGroup].elements;
                for(let iFormElement=0;iFormElement< Elements.length;iFormElement++){
                    const formElement = Elements[iFormElement];
                    let isVisible = formElement.is_visible;
    
                    const DependsOn: any = formElement.depends_on || null;
                    if(!_.isNull(DependsOn)){
                        const FormElement = this.findElementbyId(forms, DependsOn);

                        if(!isVisible && FormElement.is_visible){
                            let elementValue = this.utilHelper.getElementValue({
                                ...FormElement,
                                value: FormElement.value
                            });
                            elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                            const Dependentvalues = _.toLower(formElement.dependent_values || "").toString().trim();
                            if(_.isEqual(elementValue.length, 0)){
                                isVisible = false;
                            }else{
                                isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);
                            }
                        }else{
                            isVisible = FormElement.is_visible;
                        }
                        if(!isVisible && EXCLUDE_ELEMENT.includes(formElement.labelId)){
                            formElement.value = null;
                        }
                    }
                    Elements[iFormElement] = {
                        ...Elements[iFormElement],
                        is_visible: isVisible
                    };
                }
            }
        }

        return Forms;
    }

    /**
     * Limit multi-select dropdown
     *
     * @param {IFormElement} formElement.
     * @return {IFormElement} formElement.
     */
         public updateSelectionLimit = (formElement: IFormElement, value: any): any =>{
             const Element: IFormElement = {...formElement};
             const DataSource = (Element.dataSource || []).map((option: IOption) => ({...option, isDisabled: false}));
             const isAllSelected = value.filter((option: IOption)=>_.isEqual(option.value, UtilHelper.allOptionValue));
    
             const ValueLength = !_.isEqual(isAllSelected.length, 0) ? value.length - 1 : value.length;
             const DataSourceLength = Element.allow_all ? DataSource.length - 1 : DataSource.length;

             if(_.gte(ValueLength, Element.selection_limit)){
                  
                 Element.dataSource = Element.dataSource.map((option: IOption) => ({...option}))
                     .map((option: IOption) => {
                         if(_.isEqual(option.value, UtilHelper.allOptionValue) 
                            && _.isEqual(DataSourceLength, Element.selection_limit)){
                             return ({...option, isDisabled: false});
                         }
                         return ({...option, isDisabled: true});
                     });
             }
             else if(Element.allow_all && _.gt(DataSourceLength, Element.selection_limit)){
    
                 Element.dataSource = DataSource.map((option: IOption)=>{
                     return {
                         ...option,
                         isDisabled: _.isEqual(option.value, UtilHelper.allOptionValue)
                     };
                 });
             }else{
               
                 Element.dataSource = DataSource.map((option: IOption) => ({...option}))
                     .map((option: IOption) => ({...option, isDisabled: false}));
             }
             return Element;
         }

         /**
     * Find all child elements for DataSet
     *
     * @param {IForm[]} forms.
     * @param {id} element id.
     * @return {FormElements} IFormElement[].
     */
     findChildElementsForDataSet = (forms: IForm[], id: number): IFormElement[] => {
         const FormElements:IFormElement[] = [];
         for(const form of forms){
             for(const formGroup of form.groups){
                 for(const formElement of formGroup.elements){
                    if(!_.isNull(formElement.is_dataset)) {
                     if(!_.isEqual(formElement.parent_elements.indexOf(id), -1)){
                             FormElements.push(formElement);
                         }
                     }
                 }
             }
         }
         return FormElements;
     }

    /**
     * Map Element Value for update dataset fields
     *
     * @param {IForm[]} forms.
     * @param {IForm[]} element.
     * @return {IForm[]} prevForm.
     */
    mapElementValuesForEdit = (forms: IForm[], element: IForm[], prevForm: IForm[]): any =>{
        const ElementValues: any = [];
        const PrevFormElementValue: any = [];
        let ChildParent:any = [];
        
        element.forEach((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{
                    ElementValues.push(formElement);
                })
            }))
        }));

        prevForm.forEach((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{
                    PrevFormElementValue.push(formElement);
                })
            }))
        }));

        return forms.map((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{

                    let ValueForUpdate: any = null;
                    let ElementForUpdate: any = {};
                    let ElementForParentUpdate: any = {};
                    let ElementForDependant: any = {};
                    ElementValues.forEach((ele: any) => {
                        if (_.isEqual(formElement.id, ele.id)) {
                            ValueForUpdate = ele.value;
                        }
                    });

                    ChildParent =  this.findChildElementsForDataSet(prevForm, formElement.id);

                    PrevFormElementValue.forEach((ele: any) => {
                        if (_.isEqual(formElement.id, ele.id) && !_.isNull(formElement.is_dataset)) {
                            ElementForUpdate = ele;
                        }

                        if (!_.isEmpty(ChildParent) && _.isEqual(formElement.id, ele.id)) {
                            ElementForParentUpdate = ele;
                        }

                        if(ele.depends_on && ele.depends_on === formElement.id && !_.isNull(ele.is_dataset)){
                            ElementForDependant = PrevFormElementValue.find((e:any) => e.id === formElement.id);
                        }
                    });

                    if (!_.isNull(formElement.form_element_auth)) {
                        return {
                            ...formElement,
                        };
                    } else if (!_.isNull(formElement.is_dataset)) {
                        return {
                            ...ElementForUpdate,
                        };
                    } else if (!_.isEqual(ElementForParentUpdate, {}) && _.isEqual(ElementForParentUpdate.id, formElement.id)) {
                        return {
                            ...ElementForParentUpdate
                        };
                    } else if (!_.isEqual(ElementForDependant, {})) {
                        return {
                            ...ElementForDependant
                        };
                    } else {
                        return {
                            ...formElement,
                            value: ValueForUpdate,
                            default_value : formElement.is_default_value ? formElement.value : null
                        };
                    }
                })
            }))
        }));
    }

    //Check is parent of dataset field changed
    IsParentForDataSetFieldChanged = async (
        forms: IForm[],
        element: any
    ): Promise<any> => {
        let IsParentForDataSet = false;
    
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.is_dataset, 1) && !_.isEqual(formElement.parent_elements.length, 0)) { 
                        IsParentForDataSet = formElement.parent_elements.includes(element.formElement.id);
                    }
                }
            }
        }
        return IsParentForDataSet;
    }

    IsDependsOnForDataSetFieldChanged = async (
        forms: IForm[],
        element: any,
    ): Promise<any> => {
        let IsDependsOnForDataSet = false;

        for (const form of forms) {
            for (const formGroup of form.groups) {
                for (const formElement of formGroup.elements) {
                    if (_.isEqual(formElement.is_dataset, 1) && !_.isNull(formElement.depends_on)) {
                        IsDependsOnForDataSet = _.isEqual(formElement.depends_on,element.formElement.id);
                    }
                }
            }
        }
        return IsDependsOnForDataSet;
    }


    // Mpa Element Values for Form Reset
    mapElementForResetDataset = (initialForm: IForm[], Form: IForm[], currentElementId = 0): any =>{
        const FormFields: any = [];
        
        Form.forEach((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{
                    FormFields.push(formElement);
                })
            }))
        }));

        return initialForm.map((form: IForm)=>({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup)=>({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement)=>{
                    let ElementForChildUpdate: any = {};
                    let ElementForParentUpdate: any = {};
                    let ElementForDependant: any = {};

                    const DSChildElements =  this.findChildElementsForDataSet(Form, formElement.id);
                    const currentElement = formGroup.elements.find( x=> x.id === currentElementId);
        
                    FormFields.forEach((element: any) => {
                        if (_.isEqual(formElement.id, element.id) && !_.isNull(formElement.is_dataset)) {
                            
                            element.value = null;
                            element.touched = false;
                            element.isValid = false;
                            element.error = "";
                            if(!_.isEqual(element.depends_on, null)){
                                element.is_visible = false;
                            }
                            ElementForChildUpdate = element;
                        }
                        //to check current element parent values
                        if(currentElement && currentElement.parent_elements.includes(formElement.id) && _.isEqual(formElement.id, element.id) ){
                            ElementForDependant = element;
                        }
                        //Specifically for dataset depends_on element which is not a dataset parent in relation 
                        if(element.depends_on && element.depends_on === formElement.id && !_.isNull(element.is_dataset)){
                            ElementForDependant = FormFields.find((e:any) => e.id === formElement.id);
                        }

                        if (!_.isEmpty(DSChildElements) && _.isEqual(formElement.id, element.id)) {
                            ElementForParentUpdate = element;
                        }

                         //to auto fill the dependent values of dataset's parent 
                         if(formElement.parent_elements.includes(currentElementId) && _.gte(formElement.parent_elements.length, 1)){
                            ElementForDependant= FormFields.find((e:any)=> e.id === formElement.id);
                        }

                    });

                    if (!_.isNull(formElement.is_dataset)) {
                        return {
                            ...ElementForChildUpdate,
                        };
                    } else if (!_.isEqual(ElementForParentUpdate, {}) && _.isEqual(ElementForParentUpdate.id, formElement.id)) {
                        return {
                            ...ElementForParentUpdate
                        };
                    } else if (!_.isEqual(ElementForDependant, {})) {
                        return {
                            ...ElementForDependant
                        };
                    } else {
                        return {
                            ...formElement,
                        };
                    }
                })
            }))
        }));
    }

    mapElementForResetDependsOnDataset = (initialForm: IForm[], Form: IForm[], currentElement: number): any => {
        const FormFields: any = [];
        let isParentSkipped: any = true;

        Form.forEach((form: IForm) => ({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {
                    FormFields.push(formElement);
                })
            }))
        }));

        return initialForm.map((form: IForm) => ({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {
                    let newElement : any = {};

                    newElement = FormFields.find((e: any) => e.id === formElement.id);

                    if (!isParentSkipped && _.isEqual(newElement.disabled, false)) {
                        newElement.value = formElement.value;
                        newElement.touched = false;
                        newElement.error = "";
                        if (newElement.is_required) {
                            if (_.isBoolean(formElement.value)) {
                                newElement.isValid = true;
                            } else {
                                formElement.value ? newElement.isValid = true : newElement.isValid = false;
                            }
                        }
                    }
                    if (_.isEqual(currentElement, formElement.id)) {
                        isParentSkipped = false;
                    }

                    if (!_.isEqual(newElement, {})) {
                        return {
                            ...newElement
                        };
                    }
                })
            }))
        }));
    }

    // get API error message for Dataset field
    getAPIErrorMessegeForDD = async (
        forms: IForm[]): Promise<string> => {
        let API_ERROR = "";
    
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.is_dataset, 1) && !_.isEqual(formElement.apiError, "")) { 
                        API_ERROR= formElement.apiError;
                    }
                }
            }
        }
        return API_ERROR;
    }

    // Get API error message for Dataset field
    IsDatasetUpdatedRequest = async (
        forms: any): Promise<any> => {
        let ISDatasetUpdatedRequest = false;
    
        forms.forEach((form: IForm)=>{
            form.groups.forEach((formGroup: IFormGroup)=>{
                formGroup.elements.forEach((formElement: IFormElement)=>{
                    if(_.isEqual(formElement.is_dataset, 1) && !_.isNull(formElement.value)) { 
                        if (_.isObject(formElement.value)) {
                            ISDatasetUpdatedRequest = true;
                        } else {
                            ISDatasetUpdatedRequest = false;
                        }
                    }
                });
            });
        });
        return ISDatasetUpdatedRequest;
    }

    /**
     * Find an element by element id
     *
     * @param {IForm[]} forms.
     * @param {number} elementId.
     * @return {IFormElement} formElement.
     */
    findElementbyId = (forms: IForm[], elementId: number): any =>{
        for(const form of forms){
            for(const formGroup of form.groups){
                for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.id, elementId)) { 
                        return formElement;
                    }
                }
            }
        }
    }

    deleteFormElement = (forms: IForm[], updateElement: any): any => {
        const ids: number[]= updateElement?.formElement?.deletebyIds;
        const UpdateForms = forms.map((form: IForm) => {
            const updatedform = form.groups.map((formGroup) => {
                const FormElements: IFormElement[] = [];
                formGroup.elements.map((element: IFormElement) => {
                    const isIdPresent = ids.find(id => id === element.id);
                    if (_.isUndefined(isIdPresent)) {
                        if(_.isEqual(updateElement?.formId,form.id) && element?.add_dynamic_elements && element.add_dynamic_elements?.sourceId === element.id){
                            FormElements.push({ ...element, value: element.value - 1 });
                        } else {
                            FormElements.push(element);
                        }
                    }
                });
                return { ...formGroup, elements: FormElements };
            });
            return { ...form, groups: updatedform };
        });
        return UpdateForms;
    }
}